import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css';


import ModalLocation from './Modal/ModalCreateNewLocation.js'
import TypeAheadDestinationSavedLocation from '../FunctionalComponents/Typeahead/Destination/TypeAheadDestinationSavedLocation.js'


const FunctionalComponentBlindDestination = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  .es-c-container--delivery-destination {
    width: 100%;
  .wrapper-delivery-destination {
    // 🎛
    margin: 0 auto;
    max-width: 1360px;
    display: grid;
    grid-template-rows: 0.6fr 1fr 3fr;
    grid-template-columns: 1fr 0.1fr 0.3fr;
    grid-template-areas:
      "sg_title sg_title sg_title"
      "sg_section_1 sg_section_2 sg_section_3"
      "sg_section_4 sg_section_4 sg_section_4";

    .es-sg-title {
      grid-area: sg_title; // 🗺
      font-family: 'Teko', sans-serif;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 400;
      line-height: 1.5;
      font-size: 30px;
      color: #141b1f;
    }
    .es-sg-section-1 {
      grid-area: sg_section_1; // 🗺
      align-self: center;
    }
    .es-sg-section-2 {
      grid-area: sg_section_2; // 🗺
      align-self: center;
      justify-self: center;
      text-transform: uppercase;
      padding-top: 50px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #768287;
    }
    .es-sg-section-3 {
      grid-area: sg_section_3; // 🗺
      align-self: center;
      padding-top: 35px;
    }
    .es-sg-section-4 {
      grid-area: sg_section_4; // 🗺
      margin-top: 10px;
      .form-checkbox {
        font-family: "Open Sans";
        color: #666;
        font-size: 14px;
        font-weight: 400;
        margin-top: 35px;
      }
    }
  }
}
// Icon Styling
i {
  color: #141b1f;
}
.InputWithIcon input[type=text] {

}
.InputWithIcon {
  position: relative;
}
.InputWithIcon i{
  position: absolute;
  right: 12px;
  top: 16px;
}
.BlindDestination {
  margin-top: 20px;
}


.hide {
  display: none;
}
.es-sg-edit span {
  color: rgb(102, 102, 102);
}

`;

function showSearchResult() {
  var show = document.getElementById('destinationSearchResult').classList.contains('hide');
  document.getElementById('destinationSearchResult').classList.toggle('hide', show);
}

const PrefilledDestination = () => {
  return (
    <div id="destinationSearchResult" className="search-result hide">
      <div class="wrapper-delivery-destination">
        <div class="wrapper-row-1">
          <div class="es-sg-row-1">
            <div class="es-sg-title">
              <div class="es-t-page-title">Destination</div>
            </div>
          </div>
        </div>
        <div class="wrapper-row-2">
          <div class="es-sg-row-2">
            <div class="es-sg-subtitle">
            <div><strong>Echo - Long Beach</strong></div>
            <div>100 W Broadway,</div><div>Long Beach, CA 90802</div>
            </div>
              <div class="es-sg-name">
                <div>
                  <strong>Doug Waggoner</strong>
                </div>
                <div>312489-9827</div>
              </div>
              <div class="es-sg-edit">
                <button class="es-c-btn btn-edit">REMOVE</button>
                  <span>|</span>
                  <button class="es-c-btn btn-remove">EDIT</button>
                </div>
              </div>
            </div>
            <div class="wrapper-row-3">
            <div class="es-sg-row-3">
              <div class="es-sg-location-type">
                <div class="form-group es-i-location-type">
                  <label class="form-label" for="input-example-1">Location Type <span>*</span></label>
                  <select class="form-select select-lg">
                    <option>Location Type</option>
                    <option>Business</option>
                    <option>Residential</option>
                    <option>Construction Site</option>
                    <option>Trade Show</option>
                  </select>
                </div>
              </div>
              <div class="es-sg-special-services">
                <div class="es-i-special-services">
                  <label class="form-label" for="input-special-services">Special Services</label>
                  <div class="form-group">
                    <label><input type="checkbox" /> Lift-Gate Pickup Required <br /></label>
                    <label><input type="checkbox" /> Inside Pickup<br /></label>
                    <label><input type="checkbox" /> Limited Access Fee<br /></label>
                    <label><input type="checkbox" /> Protect From Freezing<br /></label>
                  </div>
                </div>
              </div>
              <div class="es-sg-pickup-number">
                <div class="form-group es-i-pickup-number">
                  <label class="form-label label-lg" for="input-pickup-number">Pickup Number </label>
                  <input class="form-input input-lg" type="text" id="input-pickup-number" placeholder="Optional" />
                </div>
              </div>
              <div class="es-sg-default-origin">
                <label><input type="checkbox" /> Set as a default origin</label>
                </div>
              </div>
            </div>
            <div class="wrapper-row-4">
              <div class="es-sg-row-4">
                <div class="es-sg-blind-origin">
                  <label class="form-checkbox"><input type="checkbox" /><i class="form-icon"></i> Add a Blind Origin</label>
                </div>
              </div>
            </div>
            <div class="wrapper-row-5">
              <div class="es-sg-row-5">
                <div class="es-sg-requested-pickup-date">
                  <div class="form-group es-i-requested-pickup-date">
                    <label class="form-label label-lg" for="input-requested-pickup-date">Requested Pickp Date <span>*</span></label>
                    <input class="form-input input-lg" type="text" id="input-requested-pickup-date" />
                  </div>
                </div>
                <div class="es-sg-ready-time">
                  <div class="form-group es-i-ready-time-cst">
                  <label class="form-label" for="input-state">Ready Time (CST) <span>*</span></label>
                  <select class="form-select select-lg">
                    <option value="true">12:00</option>
                    <option value="true">12:30</option>
                    <option value="true">1:00</option>
                    <option value="true">1:30</option>
                    <option value="true">2:00</option>
                    <option value="true">2:30</option>
                    <option value="true">3:00</option>
                    <option value="true">3:30</option>
                    <option value="true">4:00</option>
                    <option value="true">4:30</option>
                    <option value="true">5:00</option>
                    <option value="true">5:30</option>
                    <option value="true">6:00</option>
                    <option value="true">6:30</option>
                    <option value="true">7:00</option>
                    <option value="true">7:30</option>
                    <option value="true">8:00</option>
                    <option value="true">8:30</option>
                    <option value="true">9:00</option>
                    <option value="true">9:30</option>
                    <option value="true">10:00</option>
                    <option value="true">10:30</option>
                    <option value="true">11:00</option>
                    <option value="true">11:30</option>
                  </select>
                </div>
              </div>
              <div class="es-sg-cutoff-time">
              <div class="form-group es-i-cut-off-time-cst">
              <label class="form-label" for="input-state">Cut Off Time (CST) <span>*</span></label>
                <select class="form-select select-lg">
                  <option value="true">12:00</option>
                  <option value="true">12:30</option>
                  <option value="true">1:00</option>
                  <option value="true">1:30</option>
                  <option value="true">2:00</option>
                  <option value="true">2:30</option>
                  <option value="true">3:00</option>
                  <option value="true">3:30</option>
                  <option value="true">4:00</option>
                  <option value="true">4:30</option>
                  <option value="true">5:00</option>
                  <option value="true">5:30</option>
                  <option value="true">6:00</option>
                  <option value="true">6:30</option>
                  <option value="true">7:00</option>
                  <option value="true">7:30</option>
                  <option value="true">8:00</option>
                  <option value="true">8:30</option>
                  <option value="true">9:00</option>
                  <option value="true">9:30</option>
                  <option value="true">10:00</option>
                  <option value="true">10:30</option>
                  <option value="true">11:00</option>
                  <option value="true">11:30</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EchoShipFunctionalComponent = ({children}) => {
  return (
    <FunctionalComponentBlindDestination>
      <div className="es-c-container--delivery-destination">
        <div className="wrapper-delivery-destination">
          <div className="es-sg-title">
            <div className="es-t-page-title">Destination</div>
          </div>
          <div className="es-sg-section-1">
            <div className="form-group es-i-pickup-location">
              <label
                className="form-label label-lg"
                htmlFor="input-delivery-location"
              >
                Delivery Location <span>*</span>
              </label>

              <TypeAheadDestinationSavedLocation>
              
              </TypeAheadDestinationSavedLocation>
              
            </div>
          </div>

          <div className="es-sg-section-2">
            <p>OR</p>
          </div>
          <div className="es-sg-section-3">
            <ModalLocation>
              {children}
            </ModalLocation>
          </div>
          <div className="es-sg-section-4">

          <PrefilledDestination />
            {/* <label>
              <input type="checkbox" /> Add a Blind Destination
            </label> */}
          </div>
        </div>
      </div>
    </FunctionalComponentBlindDestination>

  )
}

export default EchoShipFunctionalComponent;