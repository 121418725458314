import React from "react";
import ToggleBlindOrigin from "./ToggleBlindOrigin";
import '../../../styles/spectre.css';
import FunctionalComponentShipmentItems from '../../FunctionalComponentShipmentItems.js'

const ShowBlindOrigin = () => {
  return (
    <div>

        <ToggleBlindOrigin>
          {({ show, toggleShow }) => (
            <div>
              <label className="form-checkbox" htmlFor="content">
              <input type="checkbox" id="content" onClick={toggleShow} />
              <i className="form-icon" /> Add a Blind Origin
              </label>
              {show && <FunctionalComponentShipmentItems />}

            </div>

          )}
        </ToggleBlindOrigin>

    </div>
  );
};

export default ShowBlindOrigin;
