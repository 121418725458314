import React from "react"
import styled from "styled-components"
import "../styles/spectre.css"
import "../styles/icons/css/icons.css"

// import 'semantic-ui-css/semantic.min.css'

import ModalLocation from "./Modal/ModalCreateNewLocation.js"

import ShowBlindOrigin from "../FunctionalComponents/HideShow/BlindOrigin/ShowBlindOrigin.js"
import TypeAheadDestinationSavedLocation from "../FunctionalComponents/Typeahead/Origin/TypeAheadOriginSavedLocation.js"

const FunctionalComponentBlindOrigin = styled.div`
  body {
    @import url("https://fonts.googleapis.com/css?family=Teko");
    font-family: "Teko", sans-serif;
  }
  .es-c-container--pickup-origin {
    width: 100%;
    .wrapper-pickup-origin {
      // 🎛
      margin: 0 auto;
      max-width: 1360px;
      display: grid;
      grid-template-rows: minmax(0.6fr, 1fr) 0.6fr 1fr 1fr;
      grid-template-columns: 1fr 0.1fr 0.3fr;
      grid-template-areas:
        "sg_title sg_title sg_title"
        "sg_section_1 sg_section_2 sg_section_3"
        "sg_section_4 sg_section_4 sg_section_4";

      .es-sg-title {
        grid-area: sg_title; // 🗺
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 400;
        line-height: 1.5;
        font-size: 30px;
        color: #141b1f;
      }
      .es-sg-section-1 {
        grid-area: sg_section_1; // 🗺
        align-self: center;
      }
      .es-sg-section-2 {
        grid-area: sg_section_2; // 🗺
        align-self: center;
        justify-self: center;
        text-transform: uppercase;
        padding-top: 50px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #768287;
      }
      .es-sg-section-3 {
        grid-area: sg_section_3; // 🗺
        align-self: center;
        padding-top: 35px;
      }
      .es-sg-section-4 {
        grid-area: sg_section_4; // 🗺
        .form-checkbox {
          font-family: "Open Sans";
          color: #666;
          font-size: 14px;
          font-weight: 400;
          margin-top: 35px;
        }
      }
    }
  }

  // Icon Styling
  i {
    color: #141b1f;
  }
  .InputWithIcon input[type="text"] {
  }
  .InputWithIcon {
    position: relative;
  }
  .InputWithIcon i {
    position: absolute;
    right: 12px;
    top: 16px;
  }
`
// $(function() { const styleLink = document.createElement("link");
// styleLink.rel = "stylesheet";
// styleLink.href =
//   "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
// document.head.appendChild(styleLink);});

const EchoShipFunctionalComponent = ({ children }) => {
  return (
    <FunctionalComponentBlindOrigin>
      <div className="es-c-container--pickup-origin">
        <div className="wrapper-pickup-origin">
          <div className="es-sg-title">
            <div className="es-t-page-title">Origin</div>
          </div>
          <div className="es-sg-section-1">
            <div className="form-group es-i-pickup-location">
              <label
                className="form-label label-lg"
                htmlFor="input-pickup-location"
              >
                PickUp Location <span>*</span>
              </label>

              <TypeAheadDestinationSavedLocation />
            </div>
          </div>
          <div className="es-sg-section-2">
            <p>OR</p>
          </div>
          <div className="es-sg-section-3">
            <ModalLocation>{children}</ModalLocation>
          </div>
          <div className="es-sg-section-4">
            <ShowBlindOrigin />
          </div>
        </div>
      </div>
    </FunctionalComponentBlindOrigin>
  )
}

export default EchoShipFunctionalComponent

// TODO: Switch to https://github.com/palmerhq/the-platform#stylesheet when it will be stable
// const styleLink = document.createElement("link");
// styleLink.rel = "stylesheet";
// styleLink.href =
//   "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
// document.head.appendChild(styleLink);


// document.addEventListener("DOMContentLoaded", function(event) {
//   const styleLink = document.createElement("link")
//   styleLink.rel = "stylesheet"
//   styleLink.href =
//     "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css"
//   document.head.appendChild(styleLink)
// })
